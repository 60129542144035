body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#naslov {
  margin-left:65px;
  line-height: normal;
}

#icon-buttons {
  display: flex;
}

#header-controls {
  margin-left: auto;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: right;
}

#header-container {
  display: flex;
  margin-left: 25px;
  margin-right: 25px;
  align-items: center;
}

#samo-logo {
  display: none;
}

.omeji-zapise {
  width: 300px;
  display: flex;
}

.map-controls {
  position: fixed;
  bottom: 70px;
  right: 30px;
  display: flex;
  flex-direction: column;
}

.drawer-list-item {
  height: 100px;
}

.drawer-header {
  margin-top: 60px;
  margin-bottom: -10px;
}

.drawer-header button {
  margin-top:10px;
}

.popup-div {
  display: flex;
}

.popup-feature-props {
  width:310px;
}

@media screen and (max-width: 1280px) { 

  #naslov { font-size:18px; }  
  
}

@media screen and (max-width: 1000px) { 

  #naslov { margin-left: 0.1vw; }  
  
}

@media screen and (max-width: 940px) { 

  #naslov { display: none; }  
  
}

@media screen and (max-width: 790px) { 
  .popup-div {
    display: flex;
    flex-direction: column;
    max-width: 312px;
  }

  .popup-div div{
    margin-bottom:10px;
  }
}

@media screen and (max-width: 720px) { 
  
  #header-container {
    flex-wrap: wrap;
    margin-left:20px;
  }

  #logo {
    display: none;
  }

  #samo-logo {
    display: block;
    max-height: 45px;
    margin-top:-3px;
    margin-left:-60px;
  }

  #header-controls {
    margin-top: -3px;
    margin-left: 0px;
    margin-right: -30px;
  }
  
  #header-container {
    justify-content: space-between;
  }
}

@media screen and (max-width: 560px) { 
  #samo-logo {
    max-height: 30px;
  }
}

@media screen and (max-width: 520px) { 
  .popup-div {
    max-width: 222px;
  }

  .popup-feature-props {
    width:220px;
  }
  
  #header-controls {
    zoom:0.95;
  }
}

@media screen and (max-width: 480px) { 
  .omeji-zapise {
    width: 140px;
  }
}

@media screen and (max-height: 380px) and (max-width: 720px) { 
  #header-container {
    margin-top:-7px;
  }
}

@media screen and (max-height: 500px) {
  .map-controls {
    flex-direction: row;
    bottom: 56px;
    right:10px;
  }
  .map-controls div{
    margin-left:10px;
  }
  .leaflet-control-minimap {
    right:5px;
    top:80px;
  }

  .leaflet-geosearch-bar {
    right: 90px;
    bottom: 108px;
  }

  .leaflet-control-layers {
    right: 90px;
    bottom: 60px;
  }
}

@media screen and (max-height: 380px) { 
  .map-controls {
    bottom: 46px;
  }

  .leaflet-control-minimap {
    top:60px;
  }

  #header-controls {
    zoom:0.95;
  }
  header {
    height:50px!important;
  }
  #header-container {
    height:50px;
  }
  #logo {
    max-height: 50px;
  }
  .map-footer {
    max-height: 30px!important;
    height: 30px;
  }
  .map-footer img{
    max-height: 30px!important;
  }

  #map{
    top: 50px;
    height: calc(100vh - 50px - 30px)!important;
  }

  .leaflet-geosearch-bar {
    right: 115px;
    position: sticky!important;
  }

  .leaflet-control-layers {
    right: 90px;
    bottom: 50px;
  }

  .leaflet-control-attribution {
    bottom: 30px;
 }
 .drawer-list-item {
    height: 54px;
  }

  .drawer-header {
    margin-top:38px;
    margin-bottom: -10px;
  }

  .drawer-header button {
    margin-top:10px;
  }
}

.menu-item-label {
  padding-right: 2px;
}

.ranljive-skupine-popup {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: 10px;
  margin-left: -10px;
}

.ranljive-skupine-popup img{
  height:20px;
}






